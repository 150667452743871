import {
  LineOutlined,
  LoadingOutlined,
  SwapRightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Col,
  Divider,
  message,
  List,
  Row,
  Select,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  Typography,
  AvatarProps,
} from "antd";
import { memo, useCallback, useMemo, useState } from "react";
import "./common.css";
import { useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import { useInfiniteLogList, useLogList } from "../Services/RQLogService";
import moment from "moment";
import { getEllipsisText } from "../utils/Util";
import { AddEditApis } from "../Services/RQTaskService";
import InfiniteScroll from "react-infinite-scroll-component";
import { IApiResponse } from "../Services/ApiUtility";
import {
  List as VList,
  ListRowProps,
} from "react-virtualized";
import { Link } from "react-router-dom";
interface IProjectTimeline {
  projectId: string;
  style?:React.CSSProperties
  getAll?:boolean
}

const ProjectTimeline = ({ projectId,style,getAll=false }: IProjectTimeline) => {
  const user = useSelector(userData);
  const [assigneeSearch, setAssigneeSearch] = useState<{
    search: string | undefined;
  }>({
    search: undefined,
  });
  const { useAssignee } = AddEditApis;
  const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
    id: projectId,
    ...assigneeSearch,
  });
  const assigneeOptions = useMemo(() => {
    return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
  }, [userAssigneeList, projectId]);
  const [logPayload, setLogPayload] = useState<{
    activeKey: string | undefined | null;
    logType: number;
    search?: string | undefined | null;
    projectId?: string | undefined | null;
    assigneeId?: string | undefined | null;
    start: number;
    length: number;
  }>({
    activeKey: "1",
    logType: 2,
    search: "",
    assigneeId: "",
    projectId: projectId,
    start: 0,
    length: 15,
  });
  const {
    data: logData,
    isPending: isLogPending,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteLogList({
    ...logPayload,
    projectId: projectId,
  },
  getAll
);

  console.log("dataInfiniteScrollItem = ", logData);

  const logList = useMemo(() => {
    let data: any[] = [];
    for (const page of logData?.pages || []) {
      for (const log of page?.result?.items || []) {
        data.push({
          ...log,
          cDate: moment(log?.cDate)?.format("LLLL"),
          remarks: getEllipsisText(
            log?.remarks?.replace(/(<([^>]+)>)/gi, ""),
            200
          ),
          from: getEllipsisText(log?.from?.replace(/(<([^>]+)>)/gi, ""), 200),
          to: getEllipsisText(log?.to?.replace(/(<([^>]+)>)/gi, ""), 200),
        });
      }
    }
    return {
      items: data,
      totalRecords: logData?.pages?.[0]?.result?.totalRecords || 0,
    };
  }, [logData]);

  // console.log("logItems = ", logList, " => ", logData);

  const [refresh, setRefresh] = useState<boolean>(false);
  const getColor = useCallback((item: any) => {
    if (item?.flag === "none") return "magenta";
    if (item?.flag === "added") return "processing";
    if (item?.flag === "remove") return "error";
    if (item?.flag === "none") return "success";
    if (item?.flag === "added") return "processing";
    if (item?.flag === "remove") return "error";
    return "magenta";
  }, []);

  const UserAvatar: React.FC<{
    AvatarProps?: AvatarProps;
    src: string | undefined | null;
  }> = memo(
    ({ AvatarProps, src }) => {
      return (
        <>
          <Avatar
            {...AvatarProps}
            shape={AvatarProps?.shape ? AvatarProps?.shape : "square"}
            src={
              !!src ? (
                <img
                  style={{ objectFit: "fill" }}
                  src={src}
                  alt="img"
                  loading="lazy"
                />
              ) : null
            }
            icon={!!!src && <UserOutlined />}
          />
        </>
      );
    },
    (p, n) => p?.src === n?.src
  );
  const renderLog = useCallback((item: any, key: string) => {
    if (item?.cngType === 1)
      return (
        <Row
          gutter={[0, 0]}
          justify={"space-between"}
          className="mt-3 me-2"
          key={key}
        >
          <Col span={2}>
            <UserAvatar src={item?.thumb} />
          </Col>
          <Col span={21}>
            <Row justify={"space-between"}>
              <Col span={18}>
                <Typography.Paragraph ellipsis className="fs13-5">
                  {item?.assigneeName}
                </Typography.Paragraph>
              </Col>
              <Col span={6}>
              <Link to={`/task/${item?.uKey}`}>
                <Typography.Link ellipsis>
                  <span className="fs13 fw500" style={{ color: "#1677FF" }}>
                    {item?.uKey}
                  </span>
                </Typography.Link>
                </Link>
              </Col>
            </Row>

            <Typography.Paragraph
              className="fs12 fw600"
              ellipsis
              title={item?.cDate}
            >
              <span className="color-secondary">{item?.cDate}</span>
            </Typography.Paragraph>

            <Typography.Paragraph className="fs12 fw600" title={item?.remarks}>
              <span className="color-secondary">{item?.remarks}</span>
            </Typography.Paragraph>
          </Col>
        </Row>
      );

    if (item?.cngType === 2)
      return (
        <Row
          gutter={[0, 0]}
          justify={"space-between"}
          className="mt-3 me-2"
          key={key}
        >
          <Col span={2}>
            <UserAvatar src={item?.thumb} />
          </Col>
          <Col span={21}>
          <Row justify={"space-between"}>
              <Col span={18}>
                <Typography.Paragraph ellipsis className="fs13-5">
                  {item?.assigneeName}
                </Typography.Paragraph>
              </Col>
              <Col span={6}>
              <Link  to={`/task/${item?.uKey}`}>

                <Typography.Link ellipsis>

                  <span className="fs13 fw500" style={{ color: "#1677FF" }}>
                    {item?.uKey}
                  </span>
                </Typography.Link>
              </Link>
              </Col>
            </Row>

            <Typography.Paragraph
              className="fs12 fw600"
              ellipsis
              title={item?.cDate}
            >
              <span className="color-secondary">{item?.cDate}</span>
            </Typography.Paragraph>

            <Typography.Paragraph
              className="fs12 fw600"
              ellipsis
              title={item?.remarks}
            >
              <span className="color-secondary">{item?.remarks}</span>
            </Typography.Paragraph>

            <Row
              justify={"space-between"}
              gutter={[0, 0]}
              className="mt-2"
              align={"middle"}
            >
              <Col span={10}>
                <Tag
                  bordered={false}
                  color="magenta"
                  className="w100 text-center"
                  title={item?.from}
                >
                  <Typography.Paragraph ellipsis className="fs12 fw500">
                    {!!item?.from ? item?.from : <LineOutlined />}
                  </Typography.Paragraph>
                </Tag>
              </Col>
              <Col span={1}>
                <SwapRightOutlined />
              </Col>
              <Col span={10}>
                <Tag
                  className="w100 text-center"
                  bordered={false}
                  color="success"
                  title={item?.to}
                >
                  <Typography.Paragraph ellipsis className="fs12 fw500">
                    {!!item?.to ? item?.to : <LineOutlined />}
                  </Typography.Paragraph>
                </Tag>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    if (item?.cngType === 3)
      return (
        <Row
          gutter={[0, 0]}
          justify={"space-between"}
          className="mt-3 me-2"
          key={key}
        >
          <Col span={2}>
            <UserAvatar src={item?.thumb} />
          </Col>
          <Col span={21}>
          <Row justify={"space-between"}>
              <Col span={18}>
                <Typography.Paragraph ellipsis className="fs13-5">
                  {item?.assigneeName}
                </Typography.Paragraph>
              </Col>
              <Col span={6}>
              <Link  to={`/task/${item?.uKey}`}>
                <Typography.Link ellipsis>
                  <span className="fs13 fw500" style={{ color: "#1677FF" }}>
                    {item?.uKey}
                  </span>
                </Typography.Link>
               </Link>
              </Col>
            </Row>

            <Typography.Paragraph
              className="fs12 fw600"
              ellipsis
              title={item?.cDate}
            >
              <span className="color-secondary">{item?.cDate}</span>
            </Typography.Paragraph>

            <Typography.Paragraph
              className="fs12 fw600"
              ellipsis
              title={item?.remarks}
            >
              <span className="color-secondary">{item?.remarks}</span>
            </Typography.Paragraph>

            {!!!item?.formatting ? (
              <Row
                justify={"space-between"}
                gutter={[0, 0]}
                className="mt-2"
                align={"middle"}
              >
                <Col span={10}>
                  <Tag
                    bordered={false}
                    color="magenta"
                    className="w100 text-center"
                    title={item?.from}
                  >
                    <Typography.Paragraph ellipsis className="fs12 fw500">
                      {!!item?.from ? item?.from : <LineOutlined />}
                    </Typography.Paragraph>
                  </Tag>
                </Col>
                <Col span={1}>
                  <SwapRightOutlined />
                </Col>
                <Col span={10}>
                  <Tag
                    className="w100 text-center"
                    bordered={false}
                    color="success"
                    title={item?.to}
                  >
                    <Typography.Paragraph ellipsis className="fs12 fw500">
                      {!!item?.to ? item?.to : <LineOutlined />}
                    </Typography.Paragraph>
                  </Tag>
                </Col>
              </Row>
            ) : (
              <Row
                justify={"space-between"}
                gutter={[0, 0]}
                className="mt-2"
                align={"middle"}
              >
                <Col span={10}>
                  <Tag
                    bordered={false}
                    color={getColor(item?.formatting?.from)}
                    className="w100 text-center"
                    title={item?.from}
                  >
                    <Typography.Paragraph
                      ellipsis
                      className="fs12 fw500"
                      delete={item?.formatting?.from?.flag === "remove"}
                    >
                      {!!item?.from ? item?.from : <LineOutlined />}
                    </Typography.Paragraph>
                  </Tag>
                </Col>
                <Col span={1}>
                  <SwapRightOutlined />
                </Col>
                <Col span={10}>
                  <Tag
                    className="w100 text-center"
                    bordered={false}
                    color={getColor(item?.formatting?.to)}
                    title={item?.to}
                  >
                    <Typography.Paragraph
                      ellipsis
                      className="fs12 fw500"
                      delete={item?.formatting?.to?.flag === "remove"}
                    >
                      {!!item?.to ? item?.to : <LineOutlined />}
                    </Typography.Paragraph>
                  </Tag>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      );
    return <div key={key}>{item?.remarks}</div>;
  }, []);

  const footer = () => {
    if (logList?.items?.length === 0 && logList?.totalRecords === 0)
      return null;
    else if (isLogPending || isFetching)
      return (
        <>
          <Typography.Text>
            <span className="color-secondary">Loading...</span>
          </Typography.Text>
        </>
      );
    else if (!hasNextPage)
      return (
        <>
          <Typography.Paragraph ellipsis title="It is all, nothing more 🤐">
            <span className="color-secondary">It is all, nothing more 🤐</span>
          </Typography.Paragraph>
        </>
      );
    else if (logList?.items?.length === 150)
      return (
        <>
          <Typography.Paragraph ellipsis title="Shows only latest 150 logs.">
            <span className="color-secondary">Shows only latest 150 logs.</span>
          </Typography.Paragraph>
        </>
      );

    return null;
  };

  const handleSelect = useCallback((value: any) => {
    setLogPayload((pre: any) => ({
      ...pre,
      assigneeId: value,
    }));
  }, []);

  const handleClear = useCallback(() => {
    setLogPayload((prev) => ({
      ...prev,
      assigneeId: undefined,
    }));
    setRefresh(!refresh);
  }, []);
  const handleSearch = useCallback((value: any) => {
    setAssigneeSearch((pre: any) => ({
      ...pre,
      search: value,
    }));
    setRefresh(!refresh);
  }, []);
  return (
    <>
      <Row justify={"space-between"}>
        <Col xl={12} lg={24} md={24} sm={24}>
          <Typography.Title level={5}>Project Timeline</Typography.Title>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24}>
          <Select
            placeholder={"Select Assignee"}
            variant="borderless"
            className="w100"
            // style={{ width: 220 }}
            allowClear
            showSearch
            onSelect={handleSelect}
            onClear={handleClear}
            options={assigneeOptions}
            onSearch={handleSearch}
          />
        </Col>
      </Row>
      <Divider
        plain
        orientation="left"
        orientationMargin="0"
        style={{ margin: "14px 0px" }}
      />
      <div className="projectTimeLine" style={{...style}}>
        <InfiniteScroll
          dataLength={logList?.items?.length}
          next={() => {
            if (logList?.items?.length < 150) {
              if (isLogPending || isFetching) return;

              fetchNextPage();
            } else return;
          }}
          hasMore={hasNextPage}
          loader={null}
          endMessage={null}
          scrollableTarget="scrollableDiv"
        >
          <VList
            id="scrollableDiv"
            key={`projectTimeLine`}
            width={window.outerWidth}
            height={666}
            rowCount={logList?.items?.length || 0}
            rowHeight={120}
            autoContainerWidth
            autoWidth
            // autoHeight
            style={{
              scrollBehavior: "smooth",
              maxHeight: 666,
            }}
            rowRenderer={(p: ListRowProps) => {
              if (p?.index < logList?.items?.length)
                return (
                  <div
                    style={{ ...p?.style, borderBottom: "1px solid #f0f0f0" }}
                    key={p?.key}
                  >
                    {renderLog(
                      logList?.items?.[p?.index],
                      `logItem-${p?.index}`
                    )}
                  </div>
                );
            }}
            overscanRowCount={3}
          />
        </InfiniteScroll>
      </div>
      <Row justify={"space-between"} className="mt-3" gutter={[0, 0]}>
        <Col span={15}>{footer()}</Col>
        <Col span={9} className="text-end">
          <Typography.Text>
            Items: {logList?.items?.length} of {logList?.totalRecords}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};
export default ProjectTimeline;
