import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./store/";
import React from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Forgotpassword from "./pages/Forgotpassword";
import Resetpassword from "./pages/Resetpassword";
import LogInForm from "./pages/Login";
// import 'antd-css-utilities/utility.min.css'
// const AsyncLogin = React.lazy(() => import("./pages/Login"));
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1, // 1 minutes
    },
  },
});
root.render(
  <>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route element={<LogInForm />} path="/login" />
              <Route path="/forgotpassword" element={<Forgotpassword />} />
              <Route
                path="/accountrecovery/ResetPassword"
                element={<Resetpassword />}
              />
              <Route path="/*" element={ <App />}/>
            </Routes>
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
