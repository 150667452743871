import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  message,

} from "antd";
import { MOBILE_VIEW_WIDTH } from "../constants/commonConstants";
import { useLogin } from "../Services/RQAuthService";
import { useDispatch } from "react-redux";
import { setUserData, userData } from "../store/slices/user-slice";

import "./Login.css";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const validateSixDigit = (_: any, value: string) => {
  if (value && value.length < 6) {
    return Promise.reject("");
  }
  return Promise.resolve();
};

const LogInForm = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync: getLoginMutateAsync, isPending } = useLogin();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const location = useLocation();
  const user = useSelector(userData);
  useEffect(() => {
    if (user?.token) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate, user]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const result = await getLoginMutateAsync(values);
      if (result?.status) {
        const user = {
          imagePath: result?.result?.imagePath,
          imageThumb: result?.result?.imageThumb,
          token: result?.result?.token,
          name: result?.result?.name,
          tokenExpiry: result?.result?.tokenExpiry,
          id: result?.result?.id,
          role: result?.result?.role,
          email: result?.result?.email,
          userName: result?.result?.userName,
          company: result?.result?.company,
          status: result?.result?.status,
        };

        dispatch(setUserData(user));
        navigate("/dashboard");
      } else {
        message.error({ content: result?.message,   duration: 2, });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const LoginComponent = () => (
    <Row align="middle" justify="center">
      <Col span={14}>
        <Row>
          <Col className="pb-5" span={24}>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <img
                style={{ width: "180px", cursor: "pointer" }}
                src={`${process.env.PUBLIC_URL}/CapsiTask.png`}
                alt="Logo"
              />
            </div>
            {/* <div
              style={{ display: "flex", justifyContent: "center", padding: 10 }}
            >
              Welcome! Log in to continue
            </div> */}
            <Divider style={{ color: "#dee2e6" }} />
          </Col>
        </Row>
        <Typography.Text
          style={{
            color: "rgb(34, 59, 149)",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          Log in to your account
        </Typography.Text>
        <div style={{ padding: 20, paddingLeft: 0 }}>
          <Form
            form={loginForm}
            name="login"
            initialValues={{ remember: true }}
            layout="vertical"
            requiredMark={true}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="userName"
              rules={[
              { required: true, message: "Please enter username!" },
              ]}
              // hasFeedback
            >
              <Input
                style={{
                  borderRadius: 0,
                  backgroundColor: "#f9faff",
                  height: 35,
                }}
                placeholder="Enter Username"
                variant="borderless"
                suffix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter password!" },
                { validator: validateSixDigit },
              ]}
              // hasFeedback
            >
              <Input.Password
                placeholder="Enter password"
                variant="borderless"
                style={{
                  borderRadius: 0,
                  backgroundColor: "#f9faff",
                  height: 35,
                }}
                // onFocus={() => setBorderColor(true)}
                // onBlur={() => setBorderColor(false)}
              />
            </Form.Item>

            <Row justify={"space-between"}>
              
              <Col span={24} className="mb-5">
                {/* <a href="#/">Forget password?</a> */}
                <Link className="forgotButton" to="/forgotpassword">
                  {" "}
                  <Typography.Link>Forgot Password?</Typography.Link>
                </Link>
              </Col>
            </Row>
            <Form.Item>
              <Row justify={"center"}>
                <Col span={6}>
                  <Button
                    // block
                    type="primary"
                    htmlType="submit"
                    loading={isPending}
                    style={
                      {
                        // height: "40px",
                        // display: "flex",
                        // backgroundColor: "#0E51D3",
                      }
                    }
                    className="mb-5"
                  >
                    LOG IN
                  </Button>
                </Col>
                <Divider style={{ color: "#dee2e6" }} />
              </Row>
            </Form.Item>
            {/* <Typography.Text className="custom-font-size">
              By Continuing to Login, you agree to Comapany's
              <Typography.Link
                href="/PrivacyPolicy.html"
                target="_blank"
                className="pl-1"
              >
                Privacy Policy .
              </Typography.Link>
            </Typography.Text> */}
            {/* <Divider style={{ color: "#dee2e6" }} /> */}
          </Form>
        </div>
      </Col>
    </Row>
  );

  return (
    <div
      style={{
        display: `${width >= MOBILE_VIEW_WIDTH ? "grid" : "block"}`,
        gridTemplateColumns: "54% 40%",
      }}
    >
      {/* <Loader loading={loading} /> */}
      {width >= MOBILE_VIEW_WIDTH && (
        <div
          style={{
            backgroundColor: "#F5F6F9",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }} className="pb-4">
            <img
              src={`${process.env.PUBLIC_URL}/tasklogin1.svg`}
              alt="Task Management"
            />

            <h2
              className="pb-2 pt-5"
              style={{
                color: "#0E51D3",
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "25px",
              }}
            >
              We're here to manage your work
            </h2>
            <h4
              style={{
                color: "#253148",
                fontFamily: "Lato",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Work Management & Time Management with CapsiTask
            </h4>
          
          </div>
        </div>
      )}
      <LoginComponent />
    </div>
  );
};

export default LogInForm;
