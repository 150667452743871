import { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Button, Input, Typography, Tooltip, Avatar } from "antd";
import BooksService from "../Services/Books";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import { AssigneeAvatar } from "./dashboard/Common/AssigneeAvatar";
import { formatDatedatetimeyeargforcreatetask } from "../utils/DateConvertor";
import TableSkeleton from "./dashboard/Common/TableSkeleton";
import { setProjectData } from "../store/slices/project-slice";
import { AppRoutesPath } from "../Routes/AppRoutesPath";
const ViewAll = () => {
  const dispatch = useDispatch();
  const [ProjectData, SetProjectData] = useState<any>(null);
  const [dataSet, setDataSet] = useState<any>([]);
  const [IsLoading, setIsLoading] = useState(false);
  const user = useSelector(userData);
  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      await BooksService.getProjectByUser(user?.id).then((res) => {
        SetProjectData(
          res?.result?.map((item: any ,index:number) => ({
            serial:index+1,
            id: item?.id,
            alias: item?.alias,
            name: item?.name,
            imagePath: item?.imagePath,
            imageThumb: "",
            todo: item?.todo,
            inprogress: item?.inprogress,
            done: item?.done,
            assignee: item?.assignee,
            createdBy: item?.createdBy?.date,
            // avatarUrl: getAvatarUrl(item?.imagePath),
          }))
        );
        setDataSet(
          res?.result?.map((item: any ,index:number) => ({
            serial:index+1,
            id: item?.id,
            alias: item?.alias,
            name: item?.name,
            imagePath: item?.imagePath,
            imageThumb: "",
            todo: item?.todo,
            inprogress: item?.inprogress,
            done: item?.done,
            assignee: item?.assignee,
            createdBy: item?.createdBy?.date,
            // avatarUrl: getAvatarUrl(item?.imagePath),
          }))
        );
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  const handleClick = ({
    projectId,
    projectName,
  }: {
    projectId: string;
    projectName: string;
  }) => {
    dispatch(
      setProjectData({
        id: projectId,
        name: projectName,
      })
    );
    navigate("/board");
  };

  const navigate = useNavigate();
  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "View all Projects",
    },
  ];
  const columns: ColumnsType = [
    {
      title:
     (
      <Typography.Paragraph ellipsis>S. No.</Typography.Paragraph>
     ),
        
      
      
      dataIndex: "serial",
      key: "serial",
      width: 50,
      fixed: 'left',
      render: (text: string, record: any, index: number) => <div  className="pl-4">{text}</div>
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      width:80,
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.alias - b.alias,
      render: (item: string, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(209, 236, 241)",
            color: "rgb(12, 84, 96)",
             width: "55px",
            border: "none",
          }}
          onClick={() =>
            handleClick({
              projectId: record?.id,
              projectName: record?.name,
            })
          }
        >
          {item}
        </Button>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      showSorterTooltip:false,
      width:350,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: string ,record:any) => (
        <Typography.Text ellipsis style={{cursor:'pointer'}}    onClick={() =>
          handleClick({
            projectId: record?.id,
            projectName: record?.name,
          })
        }>
         <Tooltip title={text}> {text}</Tooltip>
        </Typography.Text>
      ) 
    },
    {
      title:(
        <Typography.Text ellipsis>To Do</Typography.Text>
      ),
      dataIndex: "todo",
      key: "todo",
      showSorterTooltip:false,
      width:80,
      sorter: (a: any, b: any) => a.todo - b.todo,
      render: (text: number ,record:any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(209, 236, 241)",
            color: "rgb(12, 84, 96)",
            width: "5vw",
            border: "none",
          }}
          onClick={() =>
            handleClick({
              projectId: record?.id,
              projectName: record?.name,
            })
          }
        >
          {text}
        </Button>
      ),
    },
    {
     
      title:(
        <Typography.Paragraph ellipsis>In Progress</Typography.Paragraph>
      ),
      dataIndex: "inprogress",
      key: "inprogress",
      showSorterTooltip:false,
      width:80,
      sorter: (a: any, b: any) => a.inprogress - b.inprogress,
      render: (text: number ,record:any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 229, 255)",
            width: "5vw",
            border: "none",
          }}
          onClick={() =>
            handleClick({
              projectId: record?.id,
              projectName: record?.name,
            })
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Done",
      dataIndex: "done",
      key: "done",
      showSorterTooltip:false,
      width:80,
      sorter: (a: any, b: any) => a.done - b.done,
      render: (text: number ,record:any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 209, 255)",
            width: "5vw",
            border: "none",
          }}
            onClick={() =>
            handleClick({
              projectId: record?.id,
              projectName: record?.name,
            })
          }
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      showSorterTooltip: false,
      width:100,
      sorter: (a: any, b: any) => a.assignee - b.assignee,
      render: (item: any, record: any) => (
       
          
           <AssigneeAvatar data={item}  />
        
      ),
    },
    
    {
      title: "Date",
      dataIndex: "createdBy",
      key: "createdBy",
      showSorterTooltip:false,
      width:80,
      sorter: (a: any, b: any) => a.createdBy - b.createdBy,
      render: (createdBy: any) => (
        <Typography.Text ellipsis>{formatDatedatetimeyeargforcreatetask(createdBy, "invert")}</Typography.Text>
      ),
    },
  ];

  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const [refresh,setRefresh] = useState<boolean>(false)
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  return (
    <>
      <Row justify={"space-between"} className="mb-2">
        <Col span={2}>
       
          <Link to={"/dashboard"}>
            <Button type="link">
              <ArrowLeftOutlined />
            </Button>
          </Link>
        </Col>
        <Col
          className="pl-16"
          // span={4}
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          <Breadcrumb items={breadcrumbItems} />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"start"} className="mb-2">
            <Col>
              {/* <TaskFilterBar
                setprojectdata={setprojectdata}
                projectdata={projectdata}
              /> */}
              <Input
              style={{
                width: "100%",
              }}
                suffix={<SearchOutlined />}
                placeholder="Choose project"
                allowClear
                onChange={(e: any) => {
                  const value = e?.target?.value?.toLowerCase();
                  if (value) {
                    SetProjectData(
                      dataSet?.filter((item: any) => {
                        const itemName = item?.name?.toLowerCase();
                        return itemName?.includes(value);
                      })
                    );
                  } else {
                    SetProjectData(dataSet);
                  }
                }}
                type="text"
              />
            </Col>
          </Row>
          {/* <Loader loading={isLoading} /> */}
        </Col>

        <Col span={24}>
          {IsLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
            className="capTask-custom-table"
              dataSource={ProjectData}
              columns={columns}
              onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              // scroll={{ y: "75vh" }}
              scroll={{ x: 'max-content', y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingTop: "80px", width: 140 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ViewAll;
