import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";
export const useUserList = (payload: any) => {
  return useQuery({
    queryKey: ["getUserList", payload],
    queryFn: () => getUserList(payload),
  });
};

export const useAssigneeLastActivity = (payload: any) => {
  return useQuery({
    queryKey: ["AssigneeLastActivity", payload],
    queryFn: () => getAssigneeLastActivityList(payload),
  });
};

export const useLastActivityModelId = (
  userId: string | null,
  isAdmin: boolean
) => {
  return useQuery({
    queryKey: ["LastActivityModelId", userId, isAdmin],
    queryFn: () => getLastActivityModelIdList(userId, isAdmin),
    enabled: isAdmin || !!userId,
  });
};

export const usePostWeeklyReason = () => ({
  mutationFn: async (payload: any) => {
    const result = await PostWeeklyActivity(payload);
    return result;
  },
  onSuccess: (data: any) => {
    if (!data?.error) {
      queryClient.invalidateQueries({
        queryKey: ["getWeeklyActivityList"],
      });
    }
  },
});

const getUserList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.getUserList, {
    ...payload,
  });

const PostWeeklyActivity = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.WeeklyActivity, {
    ...payload,
  });

const getAssigneeLastActivityList = async (assigneeId: string) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.LastActivity + "?", {
    assigneeId,
  });

const getLastActivityModelIdList = async (
  id: string | null,
  isAdmin: boolean
) => {
  const params: Record<string, any> = { userId: id, isAdmin: true };
  const queryString = new URLSearchParams(params).toString();
  return await HTTPSCalls.GET(
    `${HTTPSCalls.ENDPOINTS.user.GetWeeklyActivityList}?${queryString}`
  );
};
