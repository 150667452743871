
export const AppRoutesPath = {
  dashboard: "dashboard",
  admindashboard: "/admin/dashboard",
  board: "board",
  viewall: "viewall",
  taskboard: "taskboard",
  profile: "profile",
  meetings: "meetings",
  backLog: "backlog",
  peoples: "peoples",
  mileStone: "mileStone",
  reports: {
    home:"reports",
    issues: "issues",
    inHand: "inHand",
    userReport:"user",
    summery:"summery",
    weeklyactivitymodel:"weeklyactivitymodel"

  },
  task: "/task/:uKey",
  setting:{
    home:"setting",
    project: "project",
    issue: "issue",
    sprint: "sprint",
    logDetail: "log-details",
    branch: "branch",
    user: "user",
    group:"group",
    addEditGroup:"add-edit-project-group",
  },
  gitHib: {
    integrate: "integrate"
  }
};