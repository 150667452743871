
import { Button, Input, message, Modal, Tag } from "antd";

import {  useState } from "react";
import { userData } from "../../../store/slices/user-slice";
import { useSelector } from "react-redux";
import { useSaveTaskLog } from "../../../Services/RQLogService";
import {
  usePostWeeklyReason,
} from "../../../Services/RQUserService";
import { isUserAdmin } from "../../../utils/Util";
const WeeklyActivityModel = ({daysInactive}:any) => {
  const user = useSelector(userData);
  const [reason, setReason] = useState("");
  const { mutationFn: activityLogMutateAsync } = usePostWeeklyReason();
  const { mutateAsync: taskLogMutateAsync } = useSaveTaskLog();
  const activityPayload = {
    assigneeName: user?.name,
    assigneeId: user?.id,
    uKey: "",
    cngType: 5,
    isComment: 2,
    remarks: `Activity Reason :- ${reason}`,
  };
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const handleReasonChange = (e: any) => {
  const value = e?.target?.value;
  setReason(value);
  setIsSaveDisabled(!value.trim());
  };
  const handleSave = async () => {
    if (reason?.trim()?.length <= 0) {
      message.error(
        "Reason input is missing. Please provide a reason before saving."
      );
      return;
    }
    try {
      const res = await activityLogMutateAsync({
        assigneeId: user?.id,
        reason: reason,
 
      });
      if (res?.message) {
        message.error(`Error in save weekly summary => ${res?.message}`);
      } else {
        await taskLogMutateAsync({
          logs: [
            { ...activityPayload, tid: res?.result?.id, pId: res?.result?.id },
          ],
        });
      }
      message.success("Successfully Save Reason!");
      // setIsModalOpen((prev) => !prev);
      return false
    } catch (error) {
      message.error("An error occurred while saving.");
    }
  };
  return (
    <>
      <Modal
        closeIcon={null}
        footer={[
          <Button
            key="save"
            type="primary"
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Save
          </Button>,
        ]}
        title={
          <>
            <Tag color="red"> Inactivity Alert :-</Tag>
            <span style={{ fontSize: 13, whiteSpace: "-moz-initial" }}>
              "You have not done any activity in{" "}
              <Tag bordered={false} color="processing">Capsitask</Tag>project since {""}
              {daysInactive || 0} days. Please explain the reason."
            </span>
          </>
        }
        open={true}
      >
        <Input.TextArea
          autoSize={{ minRows: 5 }}
          placeholder="Type a reason..."
          onChange={handleReasonChange}
        />
      </Modal>
    </>
  );
};

export default WeeklyActivityModel;
