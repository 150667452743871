import React, { ReactNode, useCallback, useMemo, useState } from "react";
import "../../common/common.css";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Segmented,
  Select,
  TimeRangePickerProps,
} from "antd";
import {
  AppstoreOutlined,
  BarsOutlined,
  CloseOutlined,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { AddEditApis } from "../../Services/RQTaskService";
import { useSelector } from "react-redux";
import { projectData, setProjectData } from "../../store/slices/project-slice";
import { userData } from "../../store/slices/user-slice";
import { useDispatch } from "react-redux";
import { priorityOptions } from "../../utils/Util";
import { dayjs } from "../../utils/dayjs";
import { boardType, setBoardType } from "../../store/slices/board-slice";

export interface ITaskFilter {
  searchText: undefined;
  searchIn: undefined;
  priority: undefined;
  sprintIds: undefined;
  teamMemberIds: undefined;
  project: undefined | {
    id:string,
    name:string
  };
  date:
    | {
        from: undefined;
        to: undefined;
      }
    | undefined;
}
export interface ITaskViewMode {
  viewMode?: "list" | "gird";
}
interface ISearch {
  user: {
    search: undefined;
  };
  assignee: {
    search: undefined;
  };
  project: {
    search: undefined;
  };
  sprint: {
    search: undefined;
  };
}

interface ITaskSearchFilter {
  frm?:any,
  hide?: "gird" | "list" | undefined | null | false;
  subFilterStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange?: (filters: ITaskFilter) => void;
  onClear?: () => void;
}

const TaskSearchFilter = ({
  frm,
  onChange,
  onClear,
  style,
  subFilterStyle,
  hide = false,
}: ITaskSearchFilter) => {
  const userType = useMemo(
    () => [
      { label: "Assignee", value: "Assignee" },
      { label: "Assigned By", value: "AssignedBy" },
    ],
    []
  );
  const [form] = Form.useForm(frm);
  const dispatch = useDispatch();
  const project = useSelector(projectData);
  const user = useSelector(userData);
  const boardView = useSelector(boardType);
  const [selectedProject, setSelectedProject] = useState<{
    label: string;
    value: string;
  }>({ label: project?.name, value: project?.id });

  const initialValues = useMemo(
    () => ({
      searchText: undefined,
      searchIn: undefined,
      priority: undefined,
      date: undefined,
      sprintIds: undefined,
      teamMemberIds: undefined,
      project: { label: project?.name, value: project?.id },
      viewMode: boardView.viewMode,
    }),
    []
  );

  const [searchFilter, setSearchFilter] = useState<ISearch>({
    user: {
      search: undefined,
    },
    assignee: {
      search: undefined,
    },
    project: {
      search: undefined,
    },
    sprint: {
      search: undefined,
    },
  });
  const { useProject, useUser, useAssignee, useSprint } = AddEditApis;
  const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
    id: selectedProject?.value,
    ...searchFilter?.assignee,
  });
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });
  const { data: sprintList, isPending: isSprintPending } = useSprint({
    id: project?.id,
    ...searchFilter?.sprint,
  });
  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);
  const assigneeOptions = useMemo(() => {
    return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
  }, [userAssigneeList, selectedProject?.value]);
  const sprintOptions = useMemo(() => {
    if (sprintList?.message)
      message.error(`Error in Sprint list options => ${sprintList?.message}`);
    return sprintList?.result?.items?.map((itm: any) => itm) || [];
  }, [sprintList]);
  const options = useMemo(
    () => [
      {
        value: "list",
        icon: <BarsOutlined />,
      },
      {
        value: "gird",
        icon: <AppstoreOutlined />,
      },
    ],
    []
  );
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const rangePresets: TimeRangePickerProps["presets"] = useMemo(
    () => [
      { label: "Today", value: [dayjs().add(0, "d"), dayjs()] },
      { label: "Yesterday", value: [dayjs().add(-1, "d"), dayjs()] },
      { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
      { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
      { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
      { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
    ],
    []
  );


  const ConfigProviderThemeProps = useMemo(
    () => ({
      components: {
        Select: {
          colorTextPlaceholder: "#95a5a6",
          fontSize: 14,
          colorPrimaryHover: "none",
          fontSizeIcon: 11,
          paddingSM: 5,
          paddingXS: 0,
          paddingXXS: 4,
          lineWidth: 1,
          borderRadius: 0,
          borderRadiusLG: 0,
          borderRadiusSM: 0,
          borderRadiusXS: 0,
          controlPaddingHorizontal: 12,
          controlPaddingHorizontalSM: 12,
          controlHeight: 28,
          controlHeightLG: 27,
          algorithm: true,
          fontWeightStrong: 600,
        },
        DatePicker: {
          controlHeight: 27,
          controlHeightLG: 27,

          hoverBorderColor: "#dee2e6",

          activeBorderColor: "#dee2e6",
        },
        Input: {
          controlHeight: 27,
          fontSizeIcon: 12,
          controlHeightLG: 27,
          inputFontSize: 14,
          colorTextPlaceholder: "#d9d9d9",
        },
      },
    }),
    []
  );

  return (
    <>
      <ConfigProvider theme={ConfigProviderThemeProps}>
        <div style={style}>
          <Form form={form} initialValues={initialValues}>
            <Row gutter={[0, 0]} justify={"space-between"} align={"middle"}>
              <Col span={4}>
                <Form.Item className="mb-0" name={"project"}>
                  <Select
                    labelInValue
                    className="capTask-custom-select w100"
                    placeholder="Select a Project"
                    options={projectOptions}
                    showSearch
                    onSearch={(value) => {
                      setSearchFilter((pre: any) => ({
                        ...pre,
                        project: {
                          search: value,
                        },
                      }));
                    }}
                    optionFilterProp="label"
                    onSelect={(value: any) => {
                      setSelectedProject({
                        label: value?.label,
                        value: value?.value,
                      });
                      dispatch(
                        setProjectData({ id: value?.value, name: value?.label })
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Row gutter={[0, 0]} justify={"end"} align={"middle"}>
                  <Col>
                    <Form.Item className="mb-0" name={"searchText"}>
                      <Input
                        className="custom-input"
                        suffix={
                          <SearchOutlined
                            style={{
                              color: "rgba(0, 0, 0, 0.45)",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          />
                        }
                        placeholder="Title and Ukey Search..."
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      className="custom-button mx-2"
                      style={{
                        height: 29,
                        borderColor: "#d3e3ff",
                        borderRadius: "4px",
                        backgroundColor: "#f3f4fa",
                      }}
                      icon={<FilterOutlined style={{ color: "#377dff" }} />}
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Item name={"viewMode"} className="mb-0">
                      <Segmented
                        style={{ minHeight: "29px", lineHeight: "29px" }}
                        options={
                          !!hide
                            ? options?.filter((x: any) => x?.value !== hide)
                            : options
                        }
                        onChange={(value: any) => {
                          dispatch(
                            setBoardType({
                              boardType: {
                                viewMode: value,
                              },
                            })
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      className="custom-button"
                      onClick={() => {
                        form.resetFields();
                      }}
                      style={{ height: 29, margin: 10 }}
                      icon={
                        <ReloadOutlined
                          style={{
                            color: "rgb(61, 153, 37)",
                            fontSize: "14px",
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {showFilters && (
              <Row
                justify={"space-between"}
                align={"middle"}
                gutter={[0, 0]}
                style={subFilterStyle}
              >
                <Col span={20}>
                  <Row justify={"start"} gutter={[10, 0]}>
                    <Col span={5}>
                      <Form.Item className="mb-0" name={"searchIn"}>
                        <Select
                          labelInValue
                          className="capTask-custom-select w100"
                          options={userType}
                          allowClear
                          placeholder="User type"
                          showSearch
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item className="mb-0" name={"priority"}>
                        <Select
                          labelInValue
                          className="capTask-custom-select"
                          placeholder="Priority"
                          options={priorityOptions}
                          showSearch
                          mode="multiple"
                          maxTagCount={"responsive"}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item className="mb-0" name={"date"}>
                        <DatePicker.RangePicker
                          className="w100"
                          presets={rangePresets}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item className="mb-0" name={"teamMemberIds"}>
                        <Select
                          labelInValue
                          className="capTask-custom-select w100"
                          placeholder="Select assignee"
                          showSearch
                          mode="multiple"
                          maxTagCount={"responsive"}
                          options={assigneeOptions}
                          optionFilterProp="label"
                          allowClear
                          searchValue={searchFilter?.assignee?.search}
                          onSearch={(value) => {
                            setSearchFilter((pre: any) => ({
                              ...pre,
                              assignee: {
                                search: value,
                              },
                            }));
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row justify={"end"} align={"middle"}>
                    <Col>
                      <Button
                        type="text"
                        style={{
                          backgroundColor: "#eef3fa",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                        }}
                        icon={
                          <ReloadOutlined
                            style={{
                              color: "rgb(211, 18, 18)",
                              fontSize: "13px",
                            }}
                          />
                        }
                        onClick={() => {
                          form.resetFields();
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        className="mx-2"
                        style={{
                          backgroundColor: "#eef3fa",
                          borderRadius: "50%",
                          height: "25px",
                          width: "25px",
                        }}
                        // shape="circle"
                        type="text"
                        icon={<CloseOutlined className="CloseOutlinedIcon" />}
                        onClick={() => {
                          setShowFilters(!showFilters);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </ConfigProvider>
    </>
  );
};
export default React.memo(TaskSearchFilter, () => true);
