import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../";

type ICollapseInitialState = {
    collapse:boolean
};

const initialState: ICollapseInitialState = {
collapse:false,
}

const collapseSlice = createSlice({
  name: "collapse",
  initialState,
  reducers: {
    setCollapse: (state, action: PayloadAction<ICollapseInitialState>) => {
      state.collapse = action.payload.collapse;
    },
  },
});

export const { setCollapse } = collapseSlice.actions;
export const collapse = (state: RootState): boolean => state.collapse.collapse

export default collapseSlice.reducer;
