import {
  Avatar,
  Button,
  Table,
  TableColumnsType,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import {
  getEllipsisText,
  statusbackgroundgenerator,
  statusconvertor,
} from "../../utils/Util";
// import AssigneeAvatar from "../../components/commoncomponents/AssigneeAvatar";
import React, { useState } from "react";
import "../../common/common.css";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { AssigneeAvatar } from "../dashboard/Common/AssigneeAvatar";
function IssuesTable({ reportList, IsLoading }: any) {
  const navigate = useNavigate();
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging?.current - 1) * paging?.pageSize);
      changeListParams("length", paging?.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  // Tabel
  const columns: TableColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "serial",
      width: 80,
      key: "serial",
      // width: "5%",
      fixed: "left",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: <Typography.Text ellipsis> UKey</Typography.Text>,
      dataIndex: "uKey",
      fixed: "left",
      width: 150,
      // width: "10%",
      render: (uKey: any, record: any) => (
        <Link className="pt-2" to={`/task/${record?.id}`}>
          <Button
            size="small"
            className="resume-btn-sm"
            style={{
       
              color: "rgb(16, 124, 16)",
              backgroundColor: "rgb(204, 229, 255)",
              width: "6vw",
              border: "none",
            }}
          >
            {priorityIcon(record?.priority)}

            <Tooltip title={uKey}>
              <Typography.Paragraph
                style={{ color: "rgb(12, 84, 96)", fontSize: "12px" }}
                ellipsis
              >
                {uKey}
              </Typography.Paragraph>
            </Tooltip>
          </Button>
        </Link>
      ),
    },
    {
      title: "Parent",
      dataIndex: "taskId",
      key: "taskId",
      showSorterTooltip: false,
      // width: "9%",
      sorter: (a: any, b: any) => a.Parent - b.Parent,
      render: (task, record) => (
        <Typography.Text
          style={{
            textAlign: "center",
            width: "80px",
          }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {record?.jira_UKey?.length > 0 ? record?.jira_UKey : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      showSorterTooltip: false,
      // width: "10%",
      sorter: (a: any, b: any) => a.Project - b.Project,
      render: (task: any, record: any) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {record?.project[0]?.name}
        </Typography.Text>
      ),
    },

    {
      title: "Task",
      dataIndex: "title",
      key: "title",
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Task - b.Task,
      // width: "15%",
      render: (text, record) => (
        <Tooltip title={getEllipsisText(record?.title, 170)}>
          <Typography.Paragraph
            style={{ cursor: "pointer" }}
            ellipsis
            onClick={() => navigate("/task/" + record?.id)}
          >
            {getEllipsisText(record?.title, 30)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "tStatus",
      showSorterTooltip: false,
      // width: "15%",
      sorter: (a: any, b: any) => a.Status - b.Status,

      render: (text: any, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          onClick={() => navigate("/task/" + record?.id)}
          style={{
            backgroundColor: statusbackgroundgenerator(
              statusconvertor(record?.tStatus)
            ),
            color: "rgb(12, 84, 96)",
            width: "125px",
            border: "none",
          }}
        >
          <Typography.Text ellipsis style={{ fontSize: "12.5px" }}>
            {statusconvertor(record?.tStatus)}
          </Typography.Text>
        </Button>
      ),
    },
    {
      title: <Typography.Text ellipsis>Assignee</Typography.Text>,
      dataIndex: "assignee",
      width: 100,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Assignee - b.Assignee,
      render: (attendees: any[]) => (
        // <div style={{ textAlign: "center", width: "80px" }}>
        //   <Avatar.Group>
        //     {attendees?.map((lead, index) => (
        //       <AssigneeAvatar
        //         size={24}
        //         assignee={lead}
        //         key={index}
        //         index={index}
        //       />
        //     ))}
        //   </Avatar.Group>
        // </div>
        <AssigneeAvatar data={attendees} />
      ),
    },
    {
      title: <Typography.Text ellipsis>Assigned By</Typography.Text>,
      dataIndex: "assignedBy",
      showSorterTooltip: false,
      // width: "13%",
      sorter: (a: any, b: any) => a.assignedBy - b.assignedBy,
      render: (attendees: any[]) => (
        <div style={{ textAlign: "center", width: "80px" }}>
          <Avatar.Group
            size="small"
            max={{
              count: 2,
            }}
          >
            {attendees?.map((lead, index) => (
              <AssigneeAvatar
              data={lead}
                // size={24}
                // assignee={lead}
                // key={index}
                // index={index}
              />
            ))}
          </Avatar.Group>
        </div>
      ),
    },
    {
      title: <Typography.Text ellipsis>Due Date</Typography.Text>,
      dataIndex: "dueDate",
      showSorterTooltip: false,
      // width: "13%",
      sorter: (a: any, b: any) => a.dueDate - b.dueDate,
      render: (text: any, record: any) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {moment(record?.dueDate)?.format("DD MMM yyyy")}
        </Typography.Text>
      ),
    },
    {
      title: <Typography.Text ellipsis>Last Updated on</Typography.Text>,
      dataIndex: "updatedBy",
      key: "Last Updated on",

      showSorterTooltip: false,
      // width: "13%",
      sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (task, record) => (
        <Typography.Text
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/task/" + record?.id)}
          ellipsis
        >
          {moment(record?.updatedBy?.date).isSame(moment(), "day")
            ? `Today ${moment(record?.updatedBy?.date).format("LT")}` // Show "Today" and time if updated today
            : moment(record?.updatedBy?.date).format("DD MMM YYYY")}
        </Typography.Text>
      ),
    },
  ];

  return (
    <div className="mt-2 mr-4 ml-4">
      {IsLoading ? (
        <TableSkeleton
          columns={columns}
          scroll={{ y: "75vh" }}
          style={{
            scrollbarWidth: "none",
            borderTop: "solid 1px #b2bec3",
          }}
        />
      ) : (
        <Table
          style={{ borderTop: "solid 1px #b2bec3" }}
          // scroll={{ y: "71vh" }}
          scroll={{ x: "max-content", y: "71vh" }}
          columns={columns}
          dataSource={reportList?.map((data: any, index: number) => ({
            serial: index + 1,
            key: index,
            ...data,
          }))}
          onChange={onChangeTable}
          pagination={{
            showTotal: (total: any, range: any) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams?.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          className="issuestabel capTask-custom-table"
          locale={{
            emptyText: (
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  paddingTop: "47px",
                }}
              >
                {/* <NoData message={undefined} /> */}
                <img
                  style={{ width: 140, paddingBottom: 15 }}
                  // src={process.env.PUBLIC_URL + "/noTask.svg"}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
                {/* <div style={{ textAlign: "center" }}>
                  <h3
                    style={{
                      margin: 0,
                      fontFamily: "Open Sans",
                      fontSize: 16,
                      color: "rgb(72 81 110)",
                    }}
                  >
                    Please Select Project
                  </h3>
                </div> */}
              </Typography.Title>
            ),
          }}
        />
      )}
    </div>
  );
}
export default IssuesTable;
