import {
  Avatar,
  Breadcrumb,
  Card,
  Col,
  ConfigProvider,
  Divider,
  List,
  Row,
  Skeleton,
} from "antd";
import React from "react";
import "../common.css";

const ProjectTimeLineLoading = () => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              algorithm: true,
              controlHeight: 25,
              controlHeightLG: 20,
              controlHeightSM: 10,
              controlHeightXS: 10,
              marginSM: 12,
              padding: 16,
            },
          },
        }}
      >
        <Row justify={"space-between"}>
          <Col xl={12} lg={24} md={24} sm={24}>
            <Skeleton.Button active size="large" shape={"round"} block />
          </Col>
          <Col xl={10} lg={24} md={24} sm={24}>
            <Skeleton.Button active size="large" shape={"round"} block />
          </Col>
        </Row>
        <Divider
          plain
          orientation="left"
          orientationMargin="0"
          style={{ margin: "14px 0px" }}
        />
        <div className="projectTimeLine">
          <Card style={{ padding: 8, borderRadius: "8px 8px 0px 0px " }}>
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              className="mt-3 me-2"
            >
              <Col span={5}>
                <Skeleton.Image active />
              </Col>
              <Col span={18}>
                <Row justify={"space-between"}>
                  <Skeleton paragraph={{ rows: 3 }} active />
                </Row>
              </Col>
            </Row>
          </Card>
          <Card
            style={{ padding: 8, borderRadius: "8px 8px 0px 0px " }}
            className="mt-3"
          >
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              className="mt-3 me-2"
            >
              <Col span={5}>
                <Skeleton.Image active />
              </Col>
              <Col span={18}>
                <Row justify={"space-between"}>
                  <Skeleton paragraph={{ rows: 3 }} active />
                </Row>
              </Col>
            </Row>
          </Card>
          <Card
            style={{ padding: 8, borderRadius: "8px 8px 0px 0px " }}
            className="mt-3"
          >
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              className="mt-3 me-2"
            >
              <Col span={5}>
                <Skeleton.Image active />
              </Col>
              <Col span={18}>
                <Row justify={"space-between"}>
                  <Skeleton paragraph={{ rows: 3 }} active />
                </Row>
              </Col>
            </Row>
          </Card>
          <Card
            style={{ padding: 8, borderRadius: "8px 8px 0px 0px " }}
            className="mt-3"
          >
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              className="mt-3 me-2"
            >
              <Col span={5}>
                <Skeleton.Image active />
              </Col>
              <Col span={18}>
                <Row justify={"space-between"}>
                  <Skeleton paragraph={{ rows: 3 }} active />
                </Row>
              </Col>
            </Row>
          </Card>
          <Card
            style={{ padding: 8, borderRadius: "8px 8px 0px 0px " }}
            className="mt-3"
          >
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              className="mt-3 me-2"
            >
              <Col span={5}>
                <Skeleton.Image active />
              </Col>
              <Col span={18}>
                <Row justify={"space-between"}>
                  <Skeleton paragraph={{ rows: 2 }} active />
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
        <Row justify={"end"} className="mt-4" gutter={[0, 0]}>
          <Col span={9} className="text-end">
            <Skeleton.Button active size="large" shape={"round"} block />
          </Col>
        </Row>
      </ConfigProvider>
    </>
  );
};

export default React.memo(ProjectTimeLineLoading, () => true);
