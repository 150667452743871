// Auth related services

import { useMutation } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";
import axios from "axios";

export const userLogin = async (payload: any) => {
  return HTTPSCalls.POST(
    `${process.env.REACT_APP_API_URL}${HTTPSCalls.ENDPOINTS.login.JWTauth}`,
    payload
  );
};

export const useLogin = () =>
  useMutation({
    mutationFn: async (payload: any) => {
      const result = await userLogin(payload);
      return result;
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({ queryKey: ["login"] });
      }
    },
  });
