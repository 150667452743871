import {
  Breadcrumb,
  Card,
  Col,
  ConfigProvider,
  Row,
  Skeleton,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { boardType } from "../../store/slices/board-slice";
import "../common.css";


const ListView: React.FC<{}> = React.memo(
  (props) => {
    return (

          <div style={{ height: "73vh", overflow: "hidden", borderRadius: 8 }}>
            <Card
              style={{ padding: 24, borderRadius: "8px 8px 0px 0px " }}
              className=""
            >
              <Skeleton active />
            </Card>
            <Card
              style={{ padding: 24, borderRadius: "8px 8px 0px 0px " }}
              className="mt-5"
            >
              <Skeleton active />
            </Card>
            <Card
              style={{ padding: 24, borderRadius: "8px 8px 0px 0px " }}
              className="mt-5"
            >
              <Skeleton active />
            </Card>
            <Card
              style={{ padding: 24, borderRadius: "8px 8px 0px 0px " }}
              className="mt-5"
            >
              <Skeleton active />
            </Card>
            <Card
              style={{ padding: 24, borderRadius: "8px 8px 0px 0px " }}
              className="mt-5"
            >
              <Skeleton active />
            </Card>
          </div>
    );
  },
  () => true
);
const GirdView = React.memo(
  () => {
    return (
      <div style={{ display: "flex", overflow: "hidden" }}>
        {[1, 2, 3, 4, 5]?.map((item: number) => (
          <div className="custom-accordion-section">
            <Row
              align={"middle"}
              justify={"space-between"}
              gutter={[0, 0]}
              style={{
                padding: "10px 10px 0px 10px",
              }}
            >
              <Skeleton.Button active size="large" shape={"round"} block />
            </Row>
            <div
              style={{
                backgroundColor: "#f2f6fe",
                width: "15vw",
                overflow: "hidden",
              }}
              className={`dnd_task task_gird`}
            >
              <Card
                style={{ padding: 12, borderRadius: "8px" }}
                className="mt-5"
              >
                <Skeleton active />
              </Card>
              <Card
                style={{ padding: 12, borderRadius: "8px" }}
                className="mt-5"
              >
                <Skeleton active />
              </Card>
              <Card
                style={{ padding: 12, borderRadius: "8px" }}
                className="mt-5"
              >
                <Skeleton active />
              </Card>
              <Card
                style={{ padding: 12, borderRadius: "8px" }}
                className="mt-5"
              >
                <Skeleton active />
              </Card>
              <Card
                style={{ padding: 12, borderRadius: "8px" }}
                className="mt-5"
              >
                <Skeleton active />
              </Card>
            </div>
          </div>
        ))}
      </div>
    );
  },
  () => true
);
const BoardBacklogLoading = () => {
  const { viewMode } = useSelector(boardType);
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              algorithm: true,
              controlHeight: 25,
              controlHeightLG: 20,
              controlHeightSM: 10,
              controlHeightXS: 10,
              marginSM: 12,
              padding: 16,
            },
          },
        }}
      >
        {viewMode === "list" ? <ListView /> : <GirdView />}
      </ConfigProvider>
    </>
  );
};

export default React.memo(BoardBacklogLoading, () => true);