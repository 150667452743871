import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { initMessageListener } from "redux-state-sync";
import userReducer from "./slices/user-slice";
import projectReducer from "./slices/project-slice";
import refreshReducer from "./slices/refresh-slice";
import taskSlice from "./slices/task-slice";
import collapseSlice from "./slices/collapse-slice"
import boardSlice from "./slices/board-slice"
const encryptTransform =
  require("redux-persist-transform-encrypt").encryptTransform;

// import { encryptTransform } from "redux-persist-transform-encrypt";
const persistConfig = {
  key: "root",
  version: 0,
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key',
      onError: function (e:any) {
        sessionStorage.removeItem('state')
      },
    }),
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  refresh: refreshReducer,
  task:taskSlice,
  collapse:collapseSlice,
  board:boardSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const saveToSessionStorage = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
    //  sessionStorage.removeItem("state");
  } catch (e) {
    console.log(e);
  }
};

store.subscribe(() => saveToSessionStorage(store.getState()));
export const persistor = persistStore(store);
initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
