import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ITaskViewMode } from "../../components/dnd/TaskSearchFilter";

type IBoardInitialState = {
    boardType:ITaskViewMode
};

const initialState: IBoardInitialState = {
  boardType:{
    viewMode:"list"
  }
}

const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    setBoardType: (state, action: PayloadAction<IBoardInitialState>) => {
      state.boardType = action.payload.boardType;
    },
  },
});

export const { setBoardType } = boardSlice.actions;
export const boardType = (state: RootState): ITaskViewMode => state.board.boardType

export default boardSlice.reducer;
