import React, { useState } from "react";
import {
  Avatar,
  Space,
  Tooltip,
  Modal,
  Table,
  TableProps,
  List,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";
import { getAvatarUrl } from "../../../utils/Util";
import { Link } from "react-router-dom";
import { AppRoutesPath } from "../../../Routes/AppRoutesPath";

interface IAssigneeAvatarProps {
  data: any[];
  maxCount?: number;
}

const RenderAvatar: React.FC<{ item: any }> = React.memo(
  (item: any) => (
    <Tooltip title={item?.name}>
      <Avatar
        src={
          item?.thumb ? (
            <img style={{ objectFit: "fill" }} src={item?.thumb} alt="img" />
          ) : null
        }
        icon={!item?.thumb && <UserOutlined />}
      />
    </Tooltip>
  ),
  () => true
);

export const AssigneeAvatar = React.memo(
  ({ data, maxCount = 2 }: IAssigneeAvatarProps) => {
    const [listParams, setListParams] = useState({
      start: 0,
      length: 5,
    });
    const [refresh, setRefresh] = useState<boolean>(false);
    const changeListParams = (key: string, value: any) => {
      setListParams((p: any) => {
        p[key] = value;
        return p;
      });

      //setListParams((x: any) => ({ ...x }));
    };
    const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
      if (paging.current !== undefined && paging.pageSize !== undefined) {
        changeListParams("start", (paging.current - 1) * paging.pageSize);
        changeListParams("length", paging.pageSize);
        setRefresh(!refresh);
      }
    };
    const pageSizeOption = ["5", "10", "15"];
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      debugger;
      setIsModalOpen(false);
    };

    return (
      <div style={{ display: "flex" }} onClick={showModal}>
        <Avatar.Group
          shape="square"
          max={{
            count: maxCount,
            style: {
              fontSize: "12.5px",
              fontWeight: 500,
            },
          }}
          size={25}
        >
          {data?.map((item: any, index: number) => (
            <div
              key={index}
              style={{ marginRight: 5 }}
              // onClick={() => navigate("/task/" + item.uKey)}
            >
              <Tooltip title={item?.name}>
                <Avatar
                  src={
                    item?.thumb ? (
                      <img
                        style={{ objectFit: "fill" }}
                        src={item?.thumb}
                        alt="img"
                        loading="lazy"
                      />
                    ) : null
                  }
                  icon={!item?.thumb && <UserOutlined />}
                />
              </Tooltip>
            </div>
          ))}
        </Avatar.Group>
        <Modal
          title="Assignee List"
          open={isModalOpen}
          footer={null} // Disable default footer buttons
          onCancel={handleCancel}
        >
          <Table
            className="capTask-custom-item"
            style={{
              border: "1px solid #dfe6e9",
              borderRadius: 3,
              padding: "12px 7px",
            }}
            dataSource={data}
            rowKey={(item: any) => item?.id}
            onChange={onChangeTable}
            pagination={{
              showTotal: (total) =>
                `${listParams.start + 1}-${Math.min(
                  listParams.start + listParams.length,
                  total
                )} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
          >
            <Table.Column
              title="S. No."
              render={(text, record, index) => listParams.start + index + 1}
            />
            <Table.Column
              title="Avatar"
              dataIndex="avatar"
              render={(text, record) => <RenderAvatar item={record} />}
            />
            <Table.Column title="Name" dataIndex="name" />
          </Table>
        </Modal>
      </div>
    );
  },
  () => true
);
