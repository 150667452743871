import { Table, TableColumnsType, TableProps, Tag, Typography } from "antd";
import React, { useState } from "react";
import "../../common/common.css";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import moment from "moment";

function SummeryTable({ WeeklyReason, isWeeklyReasonPending }: any) {
  console.log("WeeklyReason", WeeklyReason);

  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging?.current - 1) * paging?.pageSize);
      changeListParams("length", paging?.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  // Tabel
  const columns: TableColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: 10,
      render: (text: string) => <div>{text}</div>,
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: 30,
      // fixed: "left",
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (text: string, record: any, index: number) => (
        <div>{record?.createdBy?.userName}</div>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 100,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (text: string, record: any, index: number) => <div>{text}</div>,
    },

    // { 
    //   title: <Typography.Text ellipsis>Last Activity Date</Typography.Text>,
    //   dataIndex: "updatedBy",
    //   key: "Last Updated on",
    //   width: 20,
    //   showSorterTooltip: false,
    //   sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
    //   render: (task, record:any) => (
    //     <Typography.Text ellipsis style={{ fontSize: "small" }}>
    //       <Tag bordered={false} color="processing">
    //         {moment(record?.createdBy?.date).format("DD MMM YYYY")}
     
    //       </Tag>
    //     </Typography.Text>
    //   ),
    // },
  ];
  return (
    <div className="mt-2 mr-4 ml-4">
      {isWeeklyReasonPending ? (
        <TableSkeleton columns={columns} scroll={{ y: "75vh" }} />
      ) : (
        <Table
          // scroll={{ y: 55 * 5 }}
          scroll={{ y: "75vh" }}
          columns={columns}
          dataSource={WeeklyReason?.map((data: any, index: number) => ({
            serial: index + 1,
            key: index,
            ...data,
          }))}
          // dataSource={WeeklyReason}
          onChange={onChangeTable}
          pagination={{
            showTotal: (total: any, range: any) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams?.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          className="capTask-custom-table "
          locale={{
            emptyText: (
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  paddingTop: "47px",
                }}
              >
                <img
                  style={{ width: 140, paddingBottom: 15 }}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              </Typography.Title>
            ),
          }}
        />
      )}
    </div>
  );
}
export default SummeryTable;
