import {
  Breadcrumb,
  Col,
  ConfigProvider,
  Row,
  Skeleton,
} from "antd";
import React from "react";
import "../common.css";
import BoardBacklogLoading from "./BoardBacklogLoading";
import ProjectTimeLineLoading from "./ProjectTimeLineLoading";
interface ILoading {
  breadcrumbItems: any;
}
const BoardBacklogFrameLoading = ({ breadcrumbItems }: ILoading) => {
  return (
    <>
      <div>
        <Row justify={"end"} className="mb-2 px-2">
          <Col style={{ fontSize: "12px", textAlign: "center" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Skeleton: {
              algorithm: true,
              controlHeight: 25,
              controlHeightLG: 20,
              controlHeightSM: 10,
              controlHeightXS: 10,
              marginSM: 12,
              padding: 16,
            },
          },
        }}
      >
        <Row gutter={[0, 0]} justify={"space-between"}>
          {/* Task Section */}
          <Col
            md={24}
            lg={16}
            xl={17}
            className="board-rounded-card capTask-custom-col"
            // style={{ padding: "16px 24px 16px 24px" }}
          >
            <Row
              gutter={[0, 0]}
              justify={"space-between"}
              align={"middle"}
              className="mt-2 mb-5"
            >
              <Col span={4}>
                <Skeleton.Button active size="large" shape={"round"} block />
              </Col>
              <Col span={7}>
                <Skeleton.Button active size="large" shape={"round"} block />
              </Col>
            </Row>
            <BoardBacklogLoading />
          </Col>

          {/* Project Time Line */}
          <Col
            md={24}
            lg={8}
            xl={6}
            style={{
              marginTop: "8px",
              backgroundColor: "white",
              padding: "14px",
              borderRadius: 12,
              height: "83vh",
              overflow: "auto",
            }}
          >
            <ProjectTimeLineLoading />
          </Col>
        </Row>
      </ConfigProvider>
    </>
  );
};

export default React.memo(BoardBacklogFrameLoading, () => true);
