import { Breadcrumb, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import SummeryTable from "../IssuesTable/SummeryTable";
import { useLastActivityModelId } from "../../Services/RQUserService";
import { useEffect, useMemo } from "react";
import { userData } from "../../store/slices/user-slice";
import { useSelector } from "react-redux";

interface WeeklyReasonItem {
  id: string;
  title: string;
  date: string;
  // Add other fields based on your API response
}

const WeeklySummery = () => {
  const navigate = useNavigate();
  const user = useSelector(userData);
  const isAdmin = user?.role === "Admin";
  useEffect(() => {
    if (!isAdmin) {
      navigate("/dashboard");
    }
  }, []);

  const {
    data: WeeklyReason,
    isPending: isWeeklyReasonPending,
    error,
  } = useLastActivityModelId(isAdmin ? null : user?.id, isAdmin);

  const WeeklyReasonList: WeeklyReasonItem[] = useMemo(() => {
    if (!WeeklyReason?.result) return [];
    return WeeklyReason.result.map((item: WeeklyReasonItem) => ({
      ...item,
    }));
  }, [WeeklyReason]);

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/reports"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/reports"} className="BreadcrumbLink">
                    Reports
                  </Link>
                ),
              },
              {
                title: "Summery",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white">
        <Col span={24}>
          <SummeryTable
            isWeeklyReasonPending={isWeeklyReasonPending}
            WeeklyReason={WeeklyReasonList}
          />
        </Col>
      </Row>
    </>
  );
};

export default WeeklySummery;
