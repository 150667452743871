import {
  ArrowRightOutlined,
  DeleteRowOutlined,
  LikeOutlined,
  LoadingOutlined,
  MessageOutlined,
  StarOutlined,
  SwapRightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  FormItemProps,
  FormProps,
  Input,
  List,
  message,
  Row,
  Select,
  Space,
  Spin,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { NamePath } from "antd/es/form/interface";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import "./common.css";
import { useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import { projectData } from "../store/slices/project-slice";
import HTTPSCalls from "../Services/HTTPCalls";
import { useAddComment, useLogList } from "../Services/RQLogService";
import moment from "moment";
import {
  getEllipsisText,
  statusbackgroundgenerator,
  statuscolorfont,
} from "../utils/Util";
import { AddEditApis } from "../Services/RQTaskService";
import { PaginationType } from "antd/es/transfer/interface";
import { PaginationProps } from "rc-pagination";
import "../common/common.css"

interface ITaskActivity {
  taskData: any;
  projectId: string;
  pLength?: number;
}

const TaskActivity = ({ taskData, projectId, pLength = 6 }: ITaskActivity) => {
  enum LogType {
    All,
    Comment,
    History,
    Child,
    Attachment,
  }
  console.log("task");

  const [commentForm] = Form.useForm();
  const user = useSelector(userData);
  const [assigneeSearch, setAssigneeSearch] = useState<{
    search: string | undefined;
  }>({
    search: undefined,
  });
  const { useAssignee } = AddEditApis;
  const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
    id: projectId,
    ...assigneeSearch,
  });
  const assigneeOptions = useMemo(() => {
    return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
  }, [userAssigneeList, projectId]);
  const [logPayload, setLogPayload] = useState<{
    activeKey: string | undefined | null;
    logType: number;
    search?: string | undefined | null;
    taskId: string | undefined | null;
    projectId?: string | undefined | null;
    assigneeId?: string | undefined | null;
    start: number;
    length: number;
  }>({
    activeKey: "1",
    logType: 1,
    search: "",
    taskId: taskData?.id,
    assigneeId: "",
    projectId: "",
    start: 0,
    length: pLength,
  });
  const { data: logData, isPending: isLogPending } = useLogList(logPayload);
  const { mutateAsync, isPending: isCommentPostPending } = useAddComment();
  const logList = useMemo(() => {
    if (logData?.errors)
      message.error(`Error in log list => ${logData?.message}`);
    return logData?.result?.items?.map((itm: any) => ({
      ...itm,
      remarks: itm?.remarks?.replace(/(<([^>]+)>)/gi, ""),
      from: itm?.from?.replace(/(<([^>]+)>)/gi, ""),
      to: itm?.to?.replace(/(<([^>]+)>)/gi, ""),
    }));
  }, [logData]);
  // console.log("logDatalogData = ", logData);

  const [isClickOnCommentInput, setIsClickOnCommentInput] =
    useState<boolean>(false);
  const onSave = () => {
    commentForm.validateFields().then(async (values) => {
      const res = await mutateAsync({
        taskId: taskData?.id,
        user: {
          id: user?.id,
          name: user?.name,
        },
        project: {
          id: taskData?.project?.[0]?.id,
          name: taskData?.project?.[0]?.name,
        },
        logs: [
          {
            tId: taskData?.id,
            PId: taskData?.project?.[0]?.id,
            uKey: taskData?.uKey,
            assigneeId: user?.id,
            assigneeName: user?.name,
            email: user?.email,
            remarks: values?.comment,
            from: "",
            isComment: 1,
            to: "",
            cngType: 4,
            company: "63a959074e041fdc2ae91ce8",
          },
        ],
          })
          if(res?.message)
            message.error(`Error in add comment => ${res?.message}`) 
          else
          {
        commentForm?.resetFields();
        setIsClickOnCommentInput(false);
      }
            
    })
  };
  const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
    <Space>
      {/* {React.createElement(icon)} */}
      {text}
    </Space>
  );

  const changeListParams = (key: string, value: any) => {
    setLogPayload((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const [refresh, setRefresh] = useState<boolean>(false);
  const onChange = (page: number, pageSize: number) => {
    console.log("page123 = ", page, " => ", pageSize);
    changeListParams("start", (page - 1) * pageSize);
    changeListParams("length", pageSize);
    setRefresh(!refresh);
  };
  const getColor = (item:any)=>{
    if(item?.flag==="none")
      return "magenta";
    if(item?.flag==="added")
      return "processing";
    if(item?.flag==="remove")
      return "error";
    if(item?.flag==="none")
      return "success";
    if(item?.flag==="added")
      return "processing";
    if(item?.flag==="remove")
      return "error";
    return "magenta";
    
  }
  const renderLog = (item: any) => {

    if(item?.cngType===1)
      return (
        <>
        <List.Item.Meta
          avatar={<Avatar src={item.thumb} />}
          title={item?.assigneeName}
          description={
            <>
              <p className="mb-0">{moment(item?.cDate)?.format("LLLL")}</p>

              <>
                <Row justify={"space-between"} align={"top"}>
                  <Col span={24} style={{ fontSize: 12 }}>
                    {item?.remarks}
                  </Col>
                  </Row>
              </>
            </>
          }
        />
      </>
      )


    if (item?.cngType === 2)
      return (
        <>
          <List.Item.Meta
            avatar={<Avatar src={item?.thumb} />}
            title={item?.assigneeName}
            description={
              <>
                <p className="mb-0">{moment(item?.cDate)?.format("LLLL")}</p>

                <>
                  <Row justify={"space-between"} align={"top"} gutter={[0, 0]}>
                    <Col span={24} style={{ fontSize: 12 }}>
                      {item?.remarks}
                    </Col>
                    </Row>
                    <Row>
                    <Col span={24}>
                      <Row justify={"space-evenly"} gutter={[0, 0]}>
                        <Col span={11}>
                          <Tooltip title={item?.from}>
                            <Tag bordered={false} color="magenta">
                              {getEllipsisText(item?.from)}
                            </Tag>
                          </Tooltip>
                        </Col>
                        <Col span={2}>
                          <SwapRightOutlined />
                        </Col>
                        <Col span={11}>
                          <Tooltip title={item?.to}>
                            <Tag bordered={false} color="success">
                              {getEllipsisText(item?.to)}
                            </Tag>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              </>
            }
          />
        </>
      );
     if (item?.cngType === 3)
      return (
        <>
          <List.Item.Meta
            avatar={<Avatar src={item?.thumb} />}
            title={item?.assigneeName}
            description={
              <>
                <p className="mb-0">{moment(item?.cDate)?.format("LLLL")}</p>

                <>
                  <Row justify={"space-between"} align={"top"} gutter={[0, 0]}>
                    <Col span={24} style={{ fontSize: 12 }}>
                      {item?.remarks}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {!!!item?.formatting ? (
                        <Row gutter={[0, 0]}>
                          <Col span={11}>
                            <Tooltip title ={getEllipsisText(item?.from, 150)}>
                              <Tag bordered={false} color="magenta">
                                {getEllipsisText(item?.from, 50)}
                              </Tag>
                            </Tooltip>
                          </Col>
                          <Col span={2}>
                            <SwapRightOutlined />
                          </Col>
                          <Col span={11}>
                            <Tooltip title={getEllipsisText(item?.to, 150)}>
                              <Tag bordered={false} color="success">
                                {getEllipsisText(item?.to, 50)}
                              </Tag>
                            </Tooltip>
                          </Col>
                        </Row>
                      ) : (
                        // formatting
                        <Row >
                        <Col span={11}>
                          <Tooltip title={item?.from}>
                            <Tag bordered={false} color={getColor(item?.formatting?.from)} className={item?.formatting?.from?.flag==="remove"?"removeItem":""}>
                              {getEllipsisText(item?.from, 70)}
                            </Tag>
                          </Tooltip>
                        </Col>
                        <Col span={2}>
                          <SwapRightOutlined />
                        </Col>
                        <Col span={11}>
                          <Tooltip title={item?.to}>
                            <Tag bordered={false} color={getColor(item?.formatting?.to)} className={item?.formatting?.to?.flag==="remove"?"removeItem":""}>
                              {getEllipsisText(item?.to, 70)}
                            </Tag>
                          </Tooltip>
                        </Col>
                      </Row>
                      )}
                    </Col>
                  </Row>
                </>
              </>
            }
          />
        </>
      );
    return <>{item?.remarks}</>;
  };
  const pageSizeOption = ["1", "2", "3", "10", "30", "50",];
  const tabItems = [
    {
      key: "1",
      label: "Comment",
      children: (
        <>
          <List
            itemLayout="vertical"
            size="small"
            className="capTask-custom-table"
            pagination={{
              //   pageSize: 10,
              total: logData?.result?.totalRecords,
              onChange: onChange,
              showTotal: (total: any, range: any) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: logPayload?.start / logPayload?.length + 1,
              pageSize: logPayload?.length,
              showSizeChanger: true,
              pageSizeOptions: pageSizeOption,
            }}
            locale={{
              emptyText: (
                <Typography.Title
                  ellipsis
                  level={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ paddingTop: "5px", width: 110 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                </Typography.Title>
              ),
            }}
            dataSource={logList}
            renderItem={(item: any, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  avatar={<Avatar src={item?.thumb} />}
                  title={item?.assigneeName}
                  description={
                    <>
                      <p className="mb-0">
                        {moment(item?.cDate)?.format("LLLL")}
                      </p>
                      {/* <Tooltip title={item?.remarks}> */}
                        <span  style={{
                  wordBreak: "break-all",
                }}>

                       { item?.remarks }
                        </span>
                      {/* </Tooltip> */}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "History",
      children: (
        <>
          <List
            itemLayout="vertical"
            size="small"
            className="capTask-custom-table"
            pagination={{
              //   pageSize: 10,
              total: logData?.result?.totalRecords,
              onChange: onChange,
              showTotal: (total: any, range: any) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: logPayload?.start / logPayload?.length + 1,
              pageSize: logPayload?.length,
              showSizeChanger: true,
              pageSizeOptions: pageSizeOption,
            }}
            locale={{
              emptyText: (
                <Typography.Title
                  ellipsis
                  level={4}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ paddingTop: "5px", width: 110 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                </Typography.Title>
              ),
            }}
            dataSource={logList}
            renderItem={(item: any, index) => (
              <List.Item key={index}>{renderLog(item)}</List.Item>
            )}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <Spin
            spinning={
              isAssigneePending||isLogPending
            }
        indicator={<LoadingOutlined spin={false} />}
        className="DrawerPadding"
      >
        <Form
          requiredMark={false}
          form={commentForm}
          labelAlign="left"
          layout="vertical"
          className="mb-5"
        >
          <Form.Item
            name={"comment"}
            label={"Comment"}
            rules={[
              {
                required: true,
                message: "Required Field!!",
              },
            ]}
          >
            <Input.TextArea
               style={{fontSize:14.3}}
              autoSize={{ minRows: 2 }}
              placeholder="Add a comment"
              onClick={() => setIsClickOnCommentInput(true)}
            />
          </Form.Item>

          {isClickOnCommentInput ? (
            <>
              <Row gutter={10} justify={"end"}>
                <Col>
                  <Button
                    className="capTask-btn-light btn-m-sm"
                    onClick={() => {
                      commentForm?.resetFields();
                      setIsClickOnCommentInput(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    loading={isCommentPostPending}
                    className="btn-m-sm"
                    type="primary"
                    onClick={() => {
                    onSave()
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}
        </Form>

        <Tabs
          onChange={(key: string) => {
            setLogPayload((pre: any) => ({
              ...pre,
              activeKey: key,
              logType: Number(key),
            }));
          }}
          items={tabItems}
          tabBarExtraContent={
            <>
              <Select
                placeholder={"Select Assignee"}
                variant="borderless"
                className="w100 borderLessInput"
                style={{ width: 220 }}
                allowClear
                showSearch
              onSelect={(value)=>{
                setLogPayload((pre:any)=>({
                    ...pre,
                    assigneeId:value
                }))
                
                }}
              onClear={()=>{
                setLogPayload((pre:any)=>({
                    ...pre,
                    assigneeId:undefined
                }))
                setRefresh(!refresh)
                }}
                options={assigneeOptions}
                onSearch={(value) => {
                  setAssigneeSearch((pre: any) => ({
                    ...pre,
                    search: value,
                  }));
                setRefresh(!refresh)
                }}
              />
            </>
          }
        />
      </Spin>
      <></>
    </>
  );
};
export default React.memo(TaskActivity);

// import {
//   ArrowRightOutlined,
//   DashOutlined,
//   DeleteRowOutlined,
//   LikeOutlined,
//   LineOutlined,
//   LoadingOutlined,
//   MessageOutlined,
//   StarOutlined,
//   SwapRightOutlined,
//   UploadOutlined,
// } from "@ant-design/icons";
// import {
//   Avatar,
//   Button,
//   Col,
//   Divider,
//   Form,
//   FormItemProps,
//   FormProps,
//   Input,
//   List,
//   message,
//   Row,
//   Select,
//   Space,
//   Spin,
//   TableProps,
//   Tabs,
//   Tag,
//   Tooltip,
//   Typography,
//   Upload,
//   UploadProps,
// } from "antd";
// import { NamePath } from "antd/es/form/interface";
// import React, { ReactNode, useEffect, useMemo, useState } from "react";
// import "./common.css";
// import { useSelector } from "react-redux";
// import { userData } from "../store/slices/user-slice";
// import { projectData } from "../store/slices/project-slice";
// import HTTPSCalls from "../Services/HTTPCalls";
// import { useAddComment, useLogList } from "../Services/RQLogService";
// import moment from "moment";
// import {
//   getEllipsisText,
//   statusbackgroundgenerator,
//   statuscolorfont,
// } from "../utils/Util";
// import { AddEditApis } from "../Services/RQTaskService";
// import { PaginationType } from "antd/es/transfer/interface";
// import { PaginationProps } from "rc-pagination";
// import "../common/common.css";

// interface ITaskActivity {
//   isShowMore?: boolean;
//   logType?: "All" | undefined;
//   showComment?: boolean;
//   taskData: any;
//   projectId: string;
//   pLength?: number;
// }

// const TaskActivity = ({
//   taskData,
//   projectId,
//   pLength = 6,
//   showComment = true,
//   logType = undefined,
//   isShowMore = false,
// }: ITaskActivity) => {
//   enum LogType {
//     All,
//     Comment,
//     History,
//     Child,
//     Attachment,
//   }
//   console.log(
//     "projectIdprojectIdprojectIdprojectIdprojectIdprojectId",
//     projectId
//   );

//   const [commentForm] = Form.useForm();
//   const user = useSelector(userData);
//   const [assigneeSearch, setAssigneeSearch] = useState<{
//     search: string | undefined;
//   }>({
//     search: undefined,
//   });
//   const { useAssignee } = AddEditApis;
//   const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
//     id: projectId,
//     ...assigneeSearch,
//   });
//   const assigneeOptions = useMemo(() => {
//     return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
//   }, [userAssigneeList, projectId]);
//   const [logPayload, setLogPayload] = useState<{
//     activeKey: string | undefined | null;
//     logType: number;
//     search?: string | undefined | null;
//     taskId: string | undefined | null;
//     projectId?: string | undefined | null;
//     assigneeId?: string | undefined | null;
//     start: number;
//     length: number;
//   }>({
//     activeKey: "1",
//     logType: logType === "All" ? 2 : 1,
//     search: "",
//     taskId: taskData?.id,
//     assigneeId: "",
//     projectId: projectId,
//     start: 0,
//     length: pLength,
//   });
//   const { data: logData, isPending: isLogPending } = useLogList({
//     ...logPayload,
//     projectId: projectId,
//   });
//   const { mutateAsync, isPending: isCommentPostPending } = useAddComment();
//   const logList = useMemo(() => {
//     if (logData?.errors)
//       message.error(`Error in log list => ${logData?.message}`);
//     return logData?.result?.items?.map((itm: any) => ({
//       ...itm,
//       remarks: itm?.remarks?.replace(/(<([^>]+)>)/gi, ""),
//       from: itm?.from?.replace(/(<([^>]+)>)/gi, ""),
//       to: itm?.to?.replace(/(<([^>]+)>)/gi, ""),
//     }));
//   }, [logData]);
//   // console.log("logDatalogData = ", logData);

//   const [isClickOnCommentInput, setIsClickOnCommentInput] =
//     useState<boolean>(false);
//   const onSave = () => {
//     commentForm.validateFields().then(async (values) => {
//       const res = await mutateAsync({
//         taskId: taskData?.id,
//         user: {
//           id: user?.id,
//           name: user?.name,
//         },
//         project: {
//           id: taskData?.project?.[0]?.id,
//           name: taskData?.project?.[0]?.name,
//         },
//         logs: [
//           {
//             tId: taskData?.id,
//             PId: taskData?.project?.[0]?.id,
//             uKey: taskData?.uKey,
//             assigneeId: user?.id,
//             assigneeName: user?.name,
//             email: user?.email,
//             remarks: values?.comment,
//             from: "",
//             isComment: 1,
//             to: "",
//             cngType: 4,
//             company: "63a959074e041fdc2ae91ce8",
//           },
//         ],
//       });
//       if (res?.message)
//         message.error(`Error in add comment => ${res?.message}`);
//       else {
//         commentForm?.resetFields();
//         setIsClickOnCommentInput(false);
//       }
//     });
//   };
//   const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
//     <Space>
//       {/* {React.createElement(icon)} */}
//       {text}
//     </Space>
//   );

//   const changeListParams = (key: string, value: any) => {
//     setLogPayload((p: any) => {
//       p[key] = value;
//       return p;
//     });
//     //setListParams((x: any) => ({ ...x }));
//   };
//   const [refresh, setRefresh] = useState<boolean>(false);
//   const onChange = (page: number, pageSize: number) => {
//     console.log("page123 = ", page, " => ", pageSize);
//     changeListParams("start", (page - 1) * pageSize);
//     changeListParams("length", pageSize);
//     setRefresh(!refresh);
//   };
//   const getColor = (item: any) => {
//     if (item?.flag === "none") return "magenta";
//     if (item?.flag === "added") return "processing";
//     if (item?.flag === "remove") return "error";
//     if (item?.flag === "none") return "success";
//     if (item?.flag === "added") return "processing";
//     if (item?.flag === "remove") return "error";
//     return "magenta";
//   };
//   const renderLog = (item: any) => {
//     if (item?.cngType === 1)
//       return (
//         <>
//           <List.Item.Meta
//             avatar={<Avatar src={item.thumb} />}
//             title={item?.assigneeName}
//             description={
//               <>
//                 <p className="mb-0">{moment(item?.cDate)?.format("LLLL")}</p>

//                 <>
//                   <Row justify={"space-between"} align={"top"}>
//                     <Col span={24} style={{ fontSize: 12 }}>
//                       {item?.remarks}
//                     </Col>
//                   </Row>
//                 </>
//               </>
//             }
//           />
//         </>
//       );

//     if (item?.cngType === 2)
//       return (
//         <>
//           <List.Item.Meta
//             avatar={<Avatar src={item.thumb} />}
//             title={item?.assigneeName}
//             description={
//               <>
//                 <p className="mb-0">{moment(item?.cDate)?.format("LLLL")}</p>

//                 <>
//                   <Row justify={"space-between"} align={"top"} gutter={[0, 0]}>
//                     <Col span={24} style={{ fontSize: 12 }}>
//                       {item?.remarks}
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col span={24}>
//                       <Row justify={"space-evenly"} gutter={[0, 0]}>
//                         <Col span={11}>
//                           <Tooltip title={item?.from}>
//                             <Tag bordered={false} color="magenta">
//                               {getEllipsisText(item?.from)}
//                             </Tag>
//                           </Tooltip>
//                         </Col>
//                         <Col span={2}>
//                           <SwapRightOutlined />
//                         </Col>
//                         <Col span={11}>
//                           <Tooltip title={item?.to}>
//                             <Tag bordered={false} color="success">
//                               {getEllipsisText(item?.to)}
//                             </Tag>
//                           </Tooltip>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </>
//               </>
//             }
//           />
//         </>
//       );
//     if (item?.cngType === 3)
//       return (
//         <>
//           <List.Item.Meta
//             avatar={<Avatar src={item.thumb} />}
//             title={item?.assigneeName}
//             description={
//               <>
//                 <p className="mb-0">{moment(item?.cDate)?.format("LLLL")}</p>

//                 <>
//                   <Row justify={"space-between"} align={"top"} gutter={[0, 0]}>
//                     <Col span={24} style={{ fontSize: 12 }}>
//                       {item?.remarks}
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col span={24}>
//                       {!!!item?.formatting ? (
//                         <Row gutter={[0, 0]}>
//                           <Col span={11}>
//                             <Tooltip title={item?.from}>
//                               <Tag bordered={false} color="magenta">
//                                 {!!item?.from
//                                 ?
//                                 getEllipsisText(item?.from, 70)
//                                 :
//                                 <LineOutlined />
//                                 // <DashOutlined />
//             }
//                               </Tag>
//                             </Tooltip>
//                           </Col>
//                           <Col span={2}>
//                             <SwapRightOutlined />
//                           </Col>
//                           <Col span={11}>
//                             <Tooltip title={item?.to}>
//                               <Tag bordered={false} color="success">
//                                 {getEllipsisText(item?.to, 70)}
//                               </Tag>
//                             </Tooltip>
//                           </Col>
//                         </Row>
//                       ) : (
//                         // formatting
//                         <Row>
//                           <Col span={11}>
//                             <Tooltip title={item?.from}>
//                               <Tag
//                                 bordered={false}
//                                 color={getColor(item?.formatting?.from)}
//                                 className={
//                                   item?.formatting?.from?.flag === "remove"
//                                     ? "removeItem"
//                                     : ""
//                                 }
//                               >
//                                 {getEllipsisText(item?.from, 70)}
//                               </Tag>
//                             </Tooltip>
//                           </Col>
//                           <Col span={2}>
//                             <SwapRightOutlined />
//                           </Col>
//                           <Col span={11}>
//                             <Tooltip title={item?.to}>
//                               <Tag
//                                 bordered={false}
//                                 color={getColor(item?.formatting?.to)}
//                                 className={
//                                   item?.formatting?.to?.flag === "remove"
//                                     ? "removeItem"
//                                     : ""
//                                 }
//                               >
//                                 {getEllipsisText(item?.to, 70)}
//                               </Tag>
//                             </Tooltip>
//                           </Col>
//                         </Row>
//                       )}
//                     </Col>
//                   </Row>
//                 </>
//               </>
//             }
//           />
//         </>
//       );
//     return <>{item?.remarks}</>;
//   };
//   const loadMore = true ? (
//     <div
//       style={{
//         textAlign: "center",
//         marginTop: 12,
//         height: 32,
//         lineHeight: "32px",
//       }}
//     >
//       <Button onClick={() => {
//         setLogPayload({
//           ...logPayload,
//           start:logPayload?.start+10,
//           length:logPayload?.length+10
//         })
//       }}>loading more</Button>
//     </div>
//   ) : null;
//   const pageSizeOption = ["15", "30", "50", "100", "200"];
//   const tabItems = [
//     {
//       key: "1",
//       label: "Comment",
//       children: (
//         <>
//           <List
//             itemLayout="vertical"
//             size="small"
//             className="capTask-custom-table"
//             pagination={
//               isShowMore
//                 ? undefined
//                 : {
//                     //   pageSize: 10,
//                     total: logData?.result?.totalRecords,
//                     onChange: onChange,
//                     showTotal: (total: any, range: any) =>
//                       `${range[0]}-${range[1]} of ${total} items`,
//                     current: logPayload?.start / logPayload?.length + 1,
//                     pageSize: logPayload?.length,
//                     showSizeChanger: true,
//                     pageSizeOptions: pageSizeOption,
//                   }
//             }
//             locale={{
//               emptyText: (
//                 <Typography.Title
//                   ellipsis
//                   level={4}
//                   style={{
//                     textAlign: "center",
//                   }}
//                 >
//                   <img
//                     style={{ paddingTop: "5px", width: 110 }}
//                     src={process.env.PUBLIC_URL + "/img-antd.svg"}
//                     alt="No Task"
//                   />
//                 </Typography.Title>
//               ),
//             }}
//             dataSource={logList}
//             renderItem={(item: any, index) => (
//               <List.Item key={index}>
//                 <List.Item.Meta
//                   avatar={<Avatar src={item?.thumb} />}
//                   title={item?.assigneeName}
//                   description={
//                     <>
//                       <p className="mb-0">
//                         {moment(item?.cDate)?.format("LLLL")}
//                       </p>
//                       <Tooltip title={item?.remarks}>
//                         {getEllipsisText(item?.remarks, 135)}
//                       </Tooltip>
//                     </>
//                   }
//                 />
//               </List.Item>
//             )}
//           />
//         </>
//       ),
//     },
//     {
//       key: "2",
//       label: "History",
//       children: (
//         <>
//           <List
//             itemLayout="vertical"
//             size="small"
//             className="capTask-custom-table"
//             loadMore={logList?.length > 0 && isShowMore ? loadMore : false}
//             pagination={
//               isShowMore
//                 ? false
//                 : {
//                     //   pageSize: 10,
//                     total: logData?.result?.totalRecords,
//                     onChange: onChange,
//                     showTotal: (total: any, range: any) =>
//                       `${range[0]}-${range[1]} of ${total} items`,
//                     current: logPayload?.start / logPayload?.length + 1,
//                     pageSize: logPayload?.length,
//                     showSizeChanger: true,
//                     pageSizeOptions: pageSizeOption,
//                   }
//             }
//             locale={{
//               emptyText: (
//                 <Typography.Title
//                   ellipsis
//                   level={4}
//                   style={{
//                     textAlign: "center",
//                   }}
//                 >
//                   <img
//                     style={{ paddingTop: "5px", width: 110 }}
//                     src={process.env.PUBLIC_URL + "/img-antd.svg"}
//                     alt="No Task"
//                   />
//                 </Typography.Title>
//               ),
//             }}
//             dataSource={logList}
//             renderItem={(item: any, index) => (
//               <List.Item key={index}>{renderLog(item)}</List.Item>
//             )}
//           />
//         </>
//       ),
//     },
//   ];
//   console.log("32q432421342342 projectId", projectId);

//   return (
//     <>
//       <Spin
//         spinning={isAssigneePending || isLogPending}
//         indicator={<LoadingOutlined spin={false} />}
//         className="DrawerPadding"
//       >
//         {showComment ? (
//           <>
//             <Form
//               requiredMark={false}
//               form={commentForm}
//               labelAlign="left"
//               layout="vertical"
//               className="mb-5"
//             >
//               <Form.Item
//                 name={"comment"}
//                 label={"Comment"}
//                 rules={[
//                   {
//                     required: true,
//                     message: "this field is required",
//                   },
//                 ]}
//               >
//                 <Input.TextArea
//                   autoSize={{ minRows: 2 }}
//                   placeholder="Add a comment"
//                   onClick={() => setIsClickOnCommentInput(true)}
//                 />
//               </Form.Item>

//               {isClickOnCommentInput ? (
//                 <>
//                   <Row gutter={10} justify={"end"}>
//                     <Col>
//                       <Button
//                         className="capTask-btn-light btn-m-sm"
//                         onClick={() => {
//                           commentForm?.resetFields();
//                           setIsClickOnCommentInput(false);
//                         }}
//                       >
//                         Cancel
//                       </Button>
//                     </Col>
//                     <Col>
//                       <Button
//                         loading={isCommentPostPending}
//                         className="btn-m-sm"
//                         type="primary"
//                         onClick={() => {
//                           onSave();
//                         }}
//                       >
//                         Save
//                       </Button>
//                     </Col>
//                   </Row>
//                 </>
//               ) : null}
//             </Form>
//             <Tabs
//               onChange={(key: string) => {
//                 setLogPayload((pre: any) => ({
//                   ...pre,
//                   activeKey: key,
//                   logType: Number(key),
//                 }));
//               }}
//               items={tabItems}
//               tabBarExtraContent={
//                 <>
//                   <Select
//                     placeholder={"Select Assignee"}
//                     variant="borderless"
//                     className="w100 borderLessInput"
//                     style={{ width: 220 }}
//                     allowClear
//                     showSearch
//                     onSelect={(value) => {
//                       setLogPayload((pre: any) => ({
//                         ...pre,
//                         assigneeId: value,
//                       }));
//                     }}
//                     onClear={() => {
//                       setLogPayload((pre: any) => ({
//                         ...pre,
//                         assigneeId: undefined,
//                       }));
//                       setRefresh(!refresh);
//                     }}
//                     options={assigneeOptions}
//                     onSearch={(value) => {
//                       setAssigneeSearch((pre: any) => ({
//                         ...pre,
//                         search: value,
//                       }));
//                       setRefresh(!refresh);
//                     }}
//                   />
//                 </>
//               }
//             />
//           </>
//         ) : (
//           <>
//            <Row justify={"space-between"}>
//             <Col xl={12} lg={24} md={24} sm={24}>
//             <Typography.Title level={5}>Project Timeline</Typography.Title>
//             </Col>
//             <Col xl={12} lg={24} md={24} sm={24}>
//             <Select
//                   placeholder={"Select Assignee"}
//                   variant="borderless"
//                   className="w100"
//                   // style={{ width: 220 }}
//                   allowClear
//                   showSearch
//                   onSelect={(value) => {
//                     setLogPayload((pre: any) => ({
//                       ...pre,
//                       assigneeId: value,
//                     }));
//                   }}
//                   onClear={() => {
//                     setLogPayload((pre: any) => ({
//                       ...pre,
//                       assigneeId: undefined,
//                     }));
//                     setRefresh(!refresh);
//                   }}
//                   options={assigneeOptions}
//                   onSearch={(value) => {
//                     setAssigneeSearch((pre: any) => ({
//                       ...pre,
//                       search: value,
//                     }));
//                     setRefresh(!refresh);
//                   }}
//                 />
//             </Col>
//            </Row>

//             <Divider
//               plain
//               orientation="left"
//               orientationMargin="0"
//               style={{ margin: "14px 0px" }}
//             />
//             {tabItems?.[1]?.children}
//           </>
//         )}
//       </Spin>
//       <></>
//     </>
//   );
// };
// export default React.memo(TaskActivity);
